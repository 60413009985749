



















































import { Component, Vue } from 'vue-property-decorator';
// import { Store } from 'vuex';
// import { readUserProfile } from '@/store/main/getters';
import { IMeeting, IMeetingUpdate } from '@/interfaces';
import { api } from '@/api';
import { readToken } from '@/store/main/getters';
// import Meeting from '@/components/Meeting.vue'
// import { zoomApiKey } from '@/env';

@Component
export default class MeetingDetails extends Vue {
  token: string = '';

  processing_id: string = '';
  meeting: IMeeting | null=null;
  show_meeting: boolean = false;

  employee: string = '';
  note: string = '';
  
  public async mounted() {
    this.token = readToken(this.$store);
    this.processing_id = (this.$router.currentRoute.query.id as string);
    
    this.meeting = (await api.getMeeting(this.token, this.processing_id)).data;
    this.show_meeting = true;
  }

  public async update_meeting(){
    const update: IMeetingUpdate = {
      finished: true,
      employee: this.employee,
      note2: this.note
    };

    await api.updateMeeting(this.token, this.processing_id, update);
    this.$router.push('/main/dashboard');
    // this.scheduled_meeting = (await api.createMeeting(this.client, this.note)).data;
    // this.processing_id = this.scheduled_meeting.processing_id;
    // this.is_scheduled = true;
  }

}
